import {
  accumulatePrice,
  generateDeliveryFee,
  accumulateVat,
} from "../../services/computations"
import { getCouponDiscountAmount } from "../../services/coupon"
import specialCoupons from "../specialCoupons.json"
import excludedCoupons from "../excludedCoupons.json"
// import teleconsultSurvey from "../teleconsultSurvey"

export function orderConfirmation(request, files, zendeskID) {
  let {
    firstName,
    lastName,
    middleName,
    emailAddress,
    mobileNumber,
    medicines,
    deliveryAddress,
    coupon,
    birthday,
    scpwdIDNumber,
    ifMedicineNotAvailable,
    orderNotes,
    hasPartnerRequest,
    paymentOption,
    selectedBank,
    changeFor,
    nps,
  } = request

  // const handleTeleconsultSurvey = (teleconsultSurveyAnswers) => {
  //   let returnString = ""
  //   teleconsultSurvey.map((item) => {
  //     let rowValue = ""
  //     if (item.type === "checkbox" && !item.label)
  //       rowValue =
  //         teleconsultSurveyAnswers[item.valueName].length > 0 ? "Yes" : "No"
  //     else if (
  //       item.followUpQuestions &&
  //       item.followUpQuestions.length === 1 &&
  //       teleconsultSurveyAnswers[item.valueName] === "Other"
  //     )
  //       rowValue = teleconsultSurveyAnswers[item.followUpQuestionValueName]
  //     else rowValue = teleconsultSurveyAnswers[item.valueName].toString()

  //     returnString += `<tr>
  //     <td>${item.zendeskKey}</td>
  //     <td style="text-align: left;">${rowValue || "N/A"}</td>
  //     </tr> `

  //     return null
  //   })
  //   return returnString
  // }

  const handleViewMedicines = () => {
    let medicineString = ""
    medicines.map((medicine) => {
      medicineString += `<tr>
                <td>${medicine.productTitle}</td>
                <td style="text-align: right;">${medicine.qty}</td>
                <td style="text-align: right;"> Php ${(
                  medicine.qty *
                  (
                    medicine.price_list.vatex_unit_price +
                    medicine.price_list.vat
                  ).toFixed(2)
                ).toFixed(2)}</td>
            </tr> `
      return null
    })
    return medicineString
  }

  const handleViewFiles = () => {
    let fileString = ""
    files.map((file) => {
      fileString += `- ${file.filename} <br>`
      return null
    })
    return fileString
  }

  const computeOrderTotal = () => {
    let addressType = deliveryAddress?.addressType
    let province = deliveryAddress?.province?.value

    const discount = getCouponDiscountAmount(
      coupon,
      medicines,
      generateDeliveryFee({ couponCode: coupon?.code, province: province })
    )

    const accumulatedVAT = specialCoupons.includes(coupon?.code)
      ? parseFloat(accumulateVat(medicines))
      : 0

    const discountWithVat = (parseFloat(discount) + accumulatedVAT).toFixed(2)
    // if (medicines.length === 1)
    //   return parseFloat(
    //     medicines[0].qty *
    //       (
    //         medicines[0].price_list.vatex_unit_price +
    //         medicines[0].price_list.vat
    //       ).toFixed(3)
    //   ).toFixed(2);
    // const subtotal = medicines.reduce((accumulator, currentValue) => {
    //   let currentAccumulator = accumulator;
    //   if (typeof accumulator === "object")
    //     currentAccumulator = parseFloat(
    //       accumulator.qty *
    //         (
    //           accumulator.price_list.vatex_unit_price +
    //           accumulator.price_list.vat
    //         ).toFixed(2)
    //     );

    //   return (
    //     currentAccumulator +
    //     parseFloat(
    //       currentValue.qty *
    //         (
    //           currentValue.price_list.vatex_unit_price +
    //           currentValue.price_list.vat
    //         ).toFixed(2)
    //     )
    //   );
    // });
    //
    let subtotal =
      parseFloat(accumulatePrice(medicines).toFixed(2)) +
      generateDeliveryFee({
        coupon: coupon,
        couponCode: coupon?.code,
        province: province,
        addressType: addressType,
      }) -
      discountWithVat

    return `<b>${subtotal.toFixed(2)}</b>`
  }

  const zendeskIDTemplate = zendeskID
    ? `<h3 style="text-align: center;">ePharmacy Order Reference Number</h3><h3 style="text-align: center; background: #cfe1e1; padding: 1em; margin: auto; width: 20em;">${zendeskID}</h3>`
    : ""

  let addressType = deliveryAddress?.addressType
  let province = deliveryAddress?.province?.value

  const discount = getCouponDiscountAmount(
    coupon,
    medicines,
    generateDeliveryFee({ couponCode: coupon?.code, province: province })
  )

  const accumulatedVAT = specialCoupons.includes(coupon?.code)
    ? parseFloat(accumulateVat(medicines))
    : 0

  const deliveryFee = generateDeliveryFee({
    coupon: coupon,
    couponCode: coupon?.code,
    province: province,
    addressType: addressType,
  }).toFixed(2)
  // let mechanics = process[3].mechanics;

  // const handleMechanics = () => {
  //   let mechanicsString = "";
  //   mechanics.map(item => {
  //     mechanicsString += `<li>${item}</li>`;
  //     return null;
  //   });

  //   return mechanicsString;
  // };

  return /*html*/ `
    <!DOCTYPE html
    PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html lang="en">

<head>
    <style>
        body {
            font-family: 'Lato', sans-serif;
        }

        .container {
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          flex-direction: row;
          padding: 25px;
          width: 100%;
          justify-content: center;
        }
      
        @media only screen and (min-width: 770px) {
          .content {
            width: 60%;
          }
        }

        table {
            border-collapse: collapse;
            width: 100%;
        }

        table,
        th,
        td {
            border: 1px solid #ccc;
        }

        td {
            width: 30%;
            padding: 10px;
        }

        th {
            text-align: left;
            padding: 10px;
        }

        .is-bordered {
            border: 1px solid #ccc;
        }

        h3 {
          margin-top: .75rem;
        }

        .terms {
          padding: 10px;
          background: #EEEEEE;
          font-size: 11px;
          color: #606060
        }

        .has-text-right {
          text-align: right
        }

        .terms th, .terms td {
          padding: 5px;
      }
    </style>
</head>

<body>
<div class="container">
  <div class="content">
    <p>Hi ${firstName} ${lastName},</p>
    <p>Thank you for ordering from MedGrocer.</p>
    
    ${zendeskIDTemplate}
    <br />
    <p>Our pharmacist will text you during business hours (Mon - Fri, 9AM-6PM) to verify your order details. Please reply within 12 hours so that your order will not be cancelled. If you don't receive the email, please contact us by replying to this confirmation email.</p>
    
    <p>Your order will be delivered within one to four days after you confirm your order via email. We will text you on the day of delivery for the estimated time of arrival.</p>
    
    <p>Please note that the total amount below is not final until verified by our pharmacist via email.</p>

    <p>
        <h2>Review Your Responses</h2>
        Please review your responses to make sure all details are correct.
    </p>
    <h3>Personal Information</h3>
    <table class='is-bordered'>
        <tbody>
            <tr>
                <th>Name</th>
                <td>${firstName} ${middleName} ${lastName}</td>
            </tr>
            <tr>
                <th>Mobile Number</th>
                <td>${mobileNumber}</td>
            </tr>
            <tr>
                <th>Email</th>
                <td>${emailAddress}</td>
            </tr>
            <tr>
                <th>Birthday</th>
                <td>${birthday.month.value} ${birthday.date.value}, ${
    birthday.year.value
  }</td>
            </tr>
            <tr>
                <th>Delivery Address (${deliveryAddress.addressType})</th>
                <td>${deliveryAddress.streetAddress} ${
    deliveryAddress.barangay.value
  }, ${deliveryAddress.city.value}, ${deliveryAddress.province.value}${
    deliveryAddress?.notes && ` (${deliveryAddress.notes})`
  }</td>
            </tr>
            ${
              scpwdIDNumber
                ? `<tr>
                <th>SCPWD ID Number</th>
                <td>${scpwdIDNumber}</td>
            </tr>`
                : ""
            }
            <tr>
                <th>If a medicine you ordered is not available</th>
                <td>${ifMedicineNotAvailable?.value}</td>
            </tr>
            <tr>
                <th>NPS Remarks</th>
                <td>${nps}</td>
            </tr>
            <tr>
                <th>${
                  hasPartnerRequest ? "Partner Request" : "Order Notes"
                }</th>
                <td>${orderNotes}</td>
            </tr>
        </tbody>
    </table>

    <h3>Payment Details</h3>
    <table class='is-bordered'>
        <tbody>
            <tr>
                <th>Payment Method</th>
                <td>${paymentOption}</td>
            </tr>
            ${
              paymentOption === "Cash on Delivery"
                ? `<tr>
                <th>Change for</th>
                <td>PHP ${changeFor}</td>
            </tr>`
                : `<tr>
                <th>Preferred bank</th>
                <td>${selectedBank}</td>
            </tr>`
            }
        </tbody>
    </table>

    <h3>Medicines Ordered</h3>
    <table class='is-bordered'>
        <tbody>
            <tr>
                <th>SKU</th>
                <th>Quantity</th>
                <th>Total</th>
            </tr>

            ${handleViewMedicines()}

            <tr>
              <td colspan="2" class="has-text-right"><i>Convenience Fee:</i></td>
              <td class="has-text-right"><b>Php ${deliveryFee}</b></td>
            </tr>
            ${
              coupon && !excludedCoupons?.includes?.(coupon.code)
                ? `<tr>
              <td colspan="2" class="has-text-right"><i>Coupon (${
                coupon.code
              } - ${coupon?.websiteDescription || ""}):</i></td>
              <td class="has-text-right"><b>- Php ${(
                parseFloat(
                  getCouponDiscountAmount(
                    coupon,
                    medicines,
                    generateDeliveryFee({
                      couponCode: coupon?.code,
                      province: deliveryAddress.province.value,
                    })
                  )
                ) +
                (specialCoupons.includes(coupon?.code)
                  ? parseFloat(accumulateVat(medicines))
                  : 0)
              ).toFixed(2)}</b></td>
            </tr>`
                : ""
            }
            <tr>
              <td colspan="2" class="has-text-right"><b>Order Total:</b></td>
              <td class="has-text-right"><b>Php ${computeOrderTotal()}</b></td>
            </tr>
            
        </tbody>
    </table>

    <br>
        ${
          files.length > 0
            ? `
          <h3>Files Uploaded (${files.length})</h3>
              ${handleViewFiles()}
          </table>
        `
            : ""
        }
    
        
<br />
    <p>This is an automated email. For any revisions, please reply CANCEL ORDER to the verification email our pharmacist will send you and place your corrected order on medgrocer.com. This is to prevent miscommunications and minimize inaccuracies.</p>

    <p>Thank you. We hope to serve you again in the future.</p>

    <p>Regards, <br /> The MedGrocer Team</p>
    
    <b>Data Privacy</b>
      <ul>
        <li>The above information is correct and I give MedGrocer and its partners the authority to collect, store, and use my information for Medical Allowance Program and related administrative activities. Subject to certain exceptions allowed by the law, I may revise my information and inform MedGrocer and its partners to update and store the same. MedGrocer and its partners may store my information for a period of six (6) years from the end of the program without any further consent needed from my end.</li>
        <li>I will indemnify and hold MedGrocer and their partners free from any liabilities that may arise as a consequence of my decision to join the Medical Allowance Program. I also acknowledge that MedGrocer shall not be responsible nor liable for any loss or damage incurred or suffered as a consequence of: A) any delay or inability of MedGrocer to perform any of its obligations due to any mechanical, data processing, telecommunication failure, act of God, civil disturbance, any event outside MedGrocer’s control, or as a consequence of any fraud or forgery; and B) any damage to, loss of, or inability to retrieve any data that may be stored in the MedGrocer database.</li>
      </ul>
    </div>
  </div>
</div>
</body>

</html>
  `
}
