export const consultingDoctorOnline = ["Yes", "No"]

export const numberOfConsultations = [
  "1-2 times a year",
  "3-4 times a year",
  "5-6 times a year",
  "More than 6 times",
  "Other",
]

export const openForConsulting = ["Yes", "No"]

export const howMuchWillingToPay = [
  "Less than P400",
  "P400 to 800",
  "P800 to 1200",
  "P1200 to 1600",
  "P1600 to 2000",
  "More than P2000",
]
