const getFlattenedStateValue = (stateValue) => {
  const isValueNotNull = stateValue !== null
  const isValueArray = Array.isArray(stateValue)
  const isValueString = typeof stateValue === "string"
  const isObjectHasValue = stateValue?.hasOwnProperty("value")

  if (isValueString || isObjectHasValue) {
    return stateValue?.value || stateValue
  } else if (!isValueArray && isValueNotNull) {
    let stateValueObject = {}
    let stateValueKeys = Object.keys(stateValue)

    stateValueKeys.forEach((stateValueKey) => {
      stateValueObject[stateValueKey] = getFlattenedStateValue(
        stateValue[stateValueKey]
      )
    })

    return stateValueObject
  }
  return stateValue
}

export const parseInternalNote = ({ state }) => {
  let stateKeys = Object.keys(state)
  let internalNote = {}

  stateKeys.forEach((key) => {
    internalNote[key] = getFlattenedStateValue(state[key])
  })

  delete internalNote?.agreeToConsent
  return internalNote
}
