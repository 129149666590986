import React from "react"
import { Link } from "gatsby"

import Section from "../Elements/Section"
import FileThumbnail from "./UploadedDocumentsSummary/FileThumbnail"
import EditDetailsButton from "./EditDetailsButton"
import EmptyDetailsWarningMessage from "./EmptyDetailsWarningMessage"

const UploadDocumentsSummary = ({ files, route, previousRoute }) => (
  <Section
    title={`Documents Uploaded (${files.length})`}
    addOns={{
      right: <EditDetailsButton previousRoute={previousRoute} route={route} />,
    }}
  >
    {files.length < 1 ? (
      <EmptyDetailsWarningMessage
        description={
          <div className="has-text-centered">
            You have not uploaded any documents.
            <br />
            <Link to="/epharmacy/upload">Upload Documents</Link>
          </div>
        }
      />
    ) : (
      <div className="px-1 mx-1" tabIndex={0} role="button">
        <div className="columns is-vcentered is-multiline">
          {files.map((item) => (
            <div className="column is-6">
              <FileThumbnail file={item} hasFileName />
            </div>
          ))}
        </div>
      </div>
    )}
  </Section>
)

export default UploadDocumentsSummary
