export const shouldBeDisabled = (formData, medicines, documents) => {
  // if hasPartnerRequest is true, get it as the condition (because it doesn't matter if medicines section has a data on it),
  // else use the medicines.length as condition (use the normal validation of medicines.length)
  return (
    (medicines.length === 0 &&
      !formData.hasPartnerRequest &&
      !formData?.isMedGrocerWellnessPatient) ||
    !formData.firstName ||
    !formData.deliveryAddress?.streetAddress ||
    formData.agreeToConsent.length === 0
  )
}
