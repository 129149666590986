import React from "react"
import classNames from "classnames"

import Message from "elements/Message"

const OrderTotal = ({ totalPrice }) => (
  <div className={classNames("is-size-5 has-text-grey has-text-left mt-1")}>
    Order Total:{" "}
    <span className="mt-1 ml-1 has-text-black has-text-weight-bold">
      Php {totalPrice < 0 ? "0.00" : totalPrice}
    </span>
    <br />
    <Message className="mt-1">
      Order total and coupon discount will be confirmed and applied upon email
      confirmation of customer.
    </Message>
  </div>
)

export default OrderTotal
