import {
  generatePrice,
  generateDeliveryFee,
  accumulatePrice,
  accumulateVat,
} from "../../services/computations"
import { getCouponDiscountAmount } from "../../services/coupon"
import specialCoupons from "../specialCoupons.json"

import teleconsultSurvey from "../teleconsultSurvey"

export function orderTicketBody(request) {
  let {
    firstName,
    lastName,
    middleName,
    emailAddress,
    mobileNumber,
    medicines,
    deliveryAddress,
    coupon,
    paymentOption,
    changeFor,
    birthday,
    scpwdIDNumber,
    orderNotes,
    ifMedicineNotAvailable,
    hasPartnerRequest,
    healthSurvey,
    selectedBank,
    maxicareMemberCard,
    company,
    nps,
    programServiceRating,
  } = request

  let addressType = deliveryAddress?.addressType
  let province = deliveryAddress?.province?.value
  let epharmacyProgramServiceRating = programServiceRating?.value

  const discount = getCouponDiscountAmount(
    coupon,
    medicines,
    generateDeliveryFee({ couponCode: coupon?.code, province: province })
  )

  const accumulatedVAT = specialCoupons.includes(coupon?.code)
    ? parseFloat(accumulateVat(medicines))
    : 0

  const discountWithVat = (parseFloat(discount) + accumulatedVAT).toFixed(2)

  const subtotal =
    parseFloat(accumulatePrice(medicines).toFixed(2)) +
    generateDeliveryFee({
      coupon: coupon,
      couponCod: coupon?.code,
      province: province,
      addressType: addressType,
    }) -
    discountWithVat

  const deliveryFee = generateDeliveryFee({
    coupon: coupon,
    couponCode: coupon?.code,
    province: province,
    addressType: addressType,
  })

  if (!coupon?.code?.startsWith("MGBESTLIFE")) {
    maxicareMemberCard = ""
    company = ""
  }

  let medicinesArray = ""

  medicines.map((medicine) => {
    let vatex = medicine.price_list.vatex_unit_price || 0
    let vat = medicine.price_list.vat || 0

    medicinesArray += `${medicine.productTitle} #${
      medicine.qty
    }, Vatex Unit Price: ₱${vatex}, Vat: ₱${vat}, Price: ₱${generatePrice(
      medicine
    )}\n`
  })

  let teleconsultSurveyString
  let teleconsultSurveyAnswers = healthSurvey.teleconsultSurvey
  teleconsultSurvey.forEach((item) => {
    let rowValue = ""
    if (item.type === "checkbox" && !item.label)
      rowValue =
        teleconsultSurveyAnswers[item.valueName].length > 0 ? "Yes" : "No"
    else if (
      item.followUpQuestions &&
      item.followUpQuestions.length > 0 &&
      teleconsultSurveyAnswers[item.valueName] === "Other"
    )
      rowValue = teleconsultSurveyAnswers[item.followUpQuestionValueName]
    else rowValue = teleconsultSurveyAnswers[item.valueName].toString()
    teleconsultSurveyString += `${item.zendeskKey} ${rowValue} \n`
  })

  return `Customer First Name: ${firstName}
  Customer Middle Name: ${middleName}
  Customer Last Name: ${lastName}
  Customer email: ${emailAddress}
  Contact number: ${mobileNumber}
  Street Address: ${deliveryAddress.houseNumber} ${
    deliveryAddress.streetAddress
  } 
  Barangay: ${deliveryAddress.barangay.value}
  City/Municipality: ${deliveryAddress.city.value}
  Province: ${deliveryAddress.province.value}  
  Address Type: ${deliveryAddress.addressType} 
  Birthday: ${birthday.month.value} ${birthday.date.value}, ${
    birthday.year?.value
  }
  ${maxicareMemberCard && `Maxicare Card Number: ${maxicareMemberCard}`}
  ${company && `Company: ${company}`}
  Remarks: ${nps}
  Program Service Rating: ${epharmacyProgramServiceRating}
  SCPWD ID: ${scpwdIDNumber}
    
  ${medicinesArray}
    
  If a medicine you ordered is not available, do you want us to:
  ${ifMedicineNotAvailable?.value}

  Subtotal: ₱${subtotal.toFixed(2)}
  Coupon: ${coupon?.code || ""}
  Coupon Description: ${coupon?.websiteDescription || ""}
  Shipping: ${deliveryFee}
  Payment method: ${paymentOption}
  Preferred Bank: ${selectedBank || "N/A"}
  Order notes: ${orderNotes}
  Delivery notes: ${deliveryAddress?.notes}
  Change For: ${changeFor}: ${changeFor}
  ${hasPartnerRequest ? "Note: Partner Request" : ""}
  `
}
